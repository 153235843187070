import React from 'react';
import { RenderIf, ServicesFilter, Toolbar } from '@lib/ui-components';
import { ServiceType } from '@lib/api-interface';
import { ServiceTableProps } from './shared-components/service-table';
import { ServiceTab } from './service-tab';
import { RolesEnum } from '../../../../../../../../domain/enums/roles.enum';
import { useUserState } from '../../../../../../../../state/UserState';

export interface ServiceTabFilterProps {
  serviceType: ServiceType;
  active: boolean;
  serviceTable: (props: ServiceTableProps) => JSX.Element;
  subcontractorId?: string;
  hideButtons?: boolean;
}

export const ServiceTabFilter = ({ serviceType, active, serviceTable, subcontractorId, hideButtons }: ServiceTabFilterProps) => {
  const [tableFilters, setTableFilters] = React.useState<any>({});
  const userState = useUserState();
  const clientAdmin = userState.roles.findIndex((value) => value === RolesEnum.CLIENT_ADMIN) >= 0 ?? false;

  return (
    <>
      <RenderIf true={!clientAdmin}>
        <Toolbar
          left={[
            <ServicesFilter
              onFiltersChange={(filters) => {
                const activeFilters: any = {};
                filters.forEach((filter) => {
                  if (filter.value !== '') {
                    activeFilters[filter.column.key] = filter.value;
                  }
                });
                setTableFilters(activeFilters);
              }}
              serviceType={serviceType}
            />,
          ]}
        />
      </RenderIf>
      <ServiceTab serviceType={serviceType} active={active} serviceTable={serviceTable} tableFilters={tableFilters} subcontractorId={subcontractorId} hideButtons={hideButtons} />
    </>
  );
};
