import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  AddModelButton,
  DataTable,
  DataTableColumn,
  DeleteModelButton,
  EditModelButton,
  GeneralFormItem,
  GeneralFormRowOfItems,
  RenderIf,
  Snackbar,
  Toolbar,
  UsecaseButton,
} from '@lib/ui-components';
import { CompanyBranchViewModel } from '@lib/api-interface';
import * as Yup from 'yup';
import { RolesEnum } from '../../../../../domain/enums/roles.enum';
import { AuthApiService } from '../../../../../services/auth.api.service';
import { DataResultsDto } from '@lib/common-sdk';
import {
  ResetAccountPasswordButton,
} from '../../../../../components/reset-account-password-button/reset-account-password-button';
import {
  AssignProjectsButton,
} from '../../../../technical-admin/organization-users/assign-projects-button/assign-projects-button';
import { SendMailIcon } from '../../../../../libs/ui-components/components/action-buttons/SendMailIcon';
import { AuthoritiesEnum } from '../../../../../domain/enums/authorities.enum';
import { StyledBooleanTrueSign } from '../../../../../libs/ui-components/components/data-table/StyledBooleanTrueSign';

export interface CompanyBranchAccountsProps {
  companyId: string;
  role: RolesEnum | RolesEnum[];
  branchId?: string;
  hasBranch?: boolean;
  hasProjects?: boolean;
}

export const CompanyBranchAccounts = ({ companyId, role, branchId, hasBranch, hasProjects }: CompanyBranchAccountsProps) => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const withBranch = hasBranch === true && branchId === undefined;
  const roles = Array.isArray(role) ? role : [role];
  const [emailsSentMessageShown, setEmailsSentMessageShown] = React.useState<boolean>(false);

  const emailsSentMessage = React.useMemo(() => {
    return (
      <RenderIf true={emailsSentMessageShown}>
        <Snackbar message={t('accountActivationEmailSent')} severity='success' open={emailsSentMessageShown} onClose={() => setEmailsSentMessageShown(false)} />
      </RenderIf>
    );
  }, [emailsSentMessageShown]);

  const fields: (GeneralFormItem | GeneralFormRowOfItems)[] = [
    { label: t('fullName'), type: 'text', field: 'name' },
    { label: t('login'), type: 'text', field: 'login' },
    { label: t('email'), type: 'text', field: 'email' },
    { label: t('phoneNumber'), type: 'text', field: 'tags.phone' },
    {
      label: t('branch'),
      type: 'table-select',
      field: 'assignedCompanyBranchesIds',
      isVisible: () => withBranch,
      tableSelectParams: {
        modelName: 'companyBranchViews',
        modelClass: CompanyBranchViewModel,
        displayFormat: '{name}',
        dialogTitle: t('selectBranch'),
        removeHateoasPathFromResult: true,
        fetchFilters: { companyId, branchId, active: true },
        selectedValueField: 'branchId',
        columns: [
          {
            key: 'name',
            label: t('Model.CompanyBranch.name'),
            type: 'text',
          },
          {
            key: 'nip',
            label: t('nip'),
            type: 'text',
          },
        ],
      },
    },
    { label: t('comment'), type: 'text', field: 'tags.comment' },
    {
      label: t('delayedEmailNotification'),
      type: 'boolean',
      field: 'tags.delayedEmailNotification',
      defaultValue: true,
    },
    {
      label: t('seesPrices'),
      type: 'boolean',
      field: 'userSeesPrices',
      isVisible: () => roles.includes(RolesEnum.CLIENT_USER),
    },
  ];
  const columns: DataTableColumn[] = [
    {
      key: 'name',
      label: t('fullName'),
      type: 'text',
    },
    {
      key: 'login',
      label: t('login'),
      type: 'text',
    },
    {
      key: 'email',
      label: t('email'),
      type: 'text',
    },
    {
      key: 'tags.phone',
      label: t('phone'),
      type: 'text',
    },
    {
      key: 'tags.comment',
      label: t('comment'),
      type: 'text',
    },
    {
      key: 'active',
      label: t('accountActive'),
      type: 'boolean',
      align: 'center',
    },
    {
      key: 'tags.lastLogin',
      label: t('lastLogin'),
      type: 'date',
    },
    {
      key: 'tags.delayedEmailNotification',
      label: t('delayedEmailNotification'),
      type: 'boolean',
      align: 'center',
    },
    {
      key: 'userSeesPrices',
      label: t('seesPrices'),
      type: 'boolean',
      align: 'center',
      hidden: !roles.includes(RolesEnum.CLIENT_USER),
      renderer: (row) => (row.authorities.includes(AuthoritiesEnum.SEES_PRICES) ? <StyledBooleanTrueSign /> : <div />),
    },
  ];

  if (hasProjects) {
    columns.splice(8, 0, {
      key: 'assignedProjectNames',
      label: t('assignedProjects'),
      type: 'text-chip',
    });
  }

  if (hasBranch) {
    columns.splice(0, 0, {
      key: 'assignedCompanyBranchesNames',
      label: t('branch'),
      type: 'text',
    });
  }

  const companyBranchIdValidation = Yup.mixed()
    .transform((value) => {
      if (value && typeof value === 'string') {
        return [value];
      }
      return value;
    })
    .when([], {
      is: () => withBranch,
      then: Yup.array().nullable().required(t('fieldRequired')).min(1, t('fieldRequired')),
    });

  const validationSchema = {
    name: Yup.string().required(t('fieldRequired')),
    login: Yup.string().required(t('fieldRequired')),
    email: Yup.string().email(t('requiredValidEmail')).required(t('fieldRequired')),
    assignedCompanyBranchesIds: companyBranchIdValidation,
  };

  return (
    <>
      <Toolbar
        right={[
          <UsecaseButton
            onSaved={async () => {
              setEmailsSentMessageShown(true);
              setTableReloadKey(tableReloadKey + 1);
            }}
            onBeforeSave={(data) => {
              data.branchId = branchId;
              data.companyId = companyId;
              data.roles = Array.isArray(role) ? role : [role];
              return data;
            }}
            useCaseName={'SendActivationMailForBunchOfUsersUseCase'}
            groupName={'account'}
            buttonLabel={t('sendActivationMailToUsers')}
            startIcon={<SendMailIcon />}
          />,
          <AddModelButton
            buttonLabel={t('addUserAccount')}
            dialogTitle={t('addUserAccount')}
            modelName={'accounts'}
            fields={fields.map((field) => {
              if ('defaultValue' in field && !field.defaultValue) {
                return { ...field, defaultValue: undefined };
              }
              return field;
            })}
            validationSchema={validationSchema}
            onBeforeSave={(formData) => {
              const tags: any = {};
              tags.phone = formData.tags.phone;
              tags.comment = formData.tags.comment;
              tags.delayedEmailNotification = formData.tags.delayedEmailNotification;
              formData.tags = tags;
              formData.assignedCompaniesIds = [companyId];
              formData.assignedBranchesIds = branchId ? [branchId] : formData.assignedCompanyBranchesIds ? formData.assignedCompanyBranchesIds : [];
              formData.roles = roles;
              formData.authorities = roles.includes(RolesEnum.CLIENT_USER) && formData.userSeesPrices ? [AuthoritiesEnum.SEES_PRICES] : [];
              formData.password = 'xxx';
              return formData;
            }}
            overrideSaveCall={async (payload: any) => {
              const result = await AuthApiService.createUser(payload);
              if ('httpStatus' in result) {
                return new DataResultsDto(true, result.httpStatus, undefined, undefined, result);
              }
              return new DataResultsDto(false, undefined, undefined, undefined, result);
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{
          modelName: 'accounts',
          search: branchId === undefined ? 'byRoleAndCompanyId' : 'byRoleAndBranchId',
        }}
        fetchFilters={{ role, companyId, companyBranchId: branchId }}
        columns={columns}
        rowOptionsMinWidth='14rem'
        rowOptions={[
          { renderer: (row) => <ResetAccountPasswordButton login={row.login} /> },
          {
            renderer: (row) => (
              <EditModelButton
                id={row['id']}
                entity={row}
                buttonLabel={t('edit')}
                dialogTitle={t('edit')}
                modelName={'accounts'}
                fields={fields.map((field) => {
                  return {
                    ...field,
                    initialDisplayedValues: withBranch
                      ? 'field' in field && field.field === 'assignedCompanyBranchesIds'
                        ? row['assignedCompanyBranches'][0]?.name
                        : row['field' in field ? field.field : '']
                      : undefined,
                    defaultValue:
                      withBranch && 'field' in field && field.field === 'assignedCompanyBranchesIds'
                        ? row['assignedCompanyBranches'][0].companyBranchId
                        : 'field' in field && field.field === 'tags.delayedEmailNotification'
                        ? row.tags.delayedEmailNotification
                        : roles.includes(RolesEnum.CLIENT_USER) && 'field' in field && field.field === 'userSeesPrices'
                        ? row.authorities.includes(AuthoritiesEnum.SEES_PRICES)
                        : undefined,
                  } as GeneralFormItem;
                })}
                validationSchema={validationSchema}
                onBeforeSave={(formData) => {
                  const tags: any = row.tags;
                  tags.phone = formData.tags.phone;
                  tags.comment = formData.tags.comment;
                  tags.delayedEmailNotification = formData.tags.delayedEmailNotification;
                  formData.id = row.id;
                  formData.tags = tags;
                  formData.assignedBranchesIds = formData.assignedCompanyBranchesIds ? formData.assignedCompanyBranchesIds : [];
                  formData.authorities = roles.includes(RolesEnum.CLIENT_USER) && formData.userSeesPrices ? [AuthoritiesEnum.SEES_PRICES] : [];
                  return formData;
                }}
                overrideSaveCall={async (payload: any) => {
                  const result = await AuthApiService.updateUser(payload);
                  if (result && 'httpStatus' in result) {
                    return new DataResultsDto(true, result.httpStatus, undefined, undefined, result);
                  }
                  return new DataResultsDto(false, undefined, undefined, undefined, result);
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                yellowIcon
              />
            ),
          },
          {
            isVisible: () => hasProjects === true,
            renderer: (row) => <AssignProjectsButton accountId={row.id} onSaved={() => setTableReloadKey((prevState) => prevState + 1)} projectSingleSelect={true} />,
          },
          {
            renderer: (row) => <DeleteModelButton id={row.id} modelName='accounts' onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
      {emailsSentMessage}
    </>
  );
};
