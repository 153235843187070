import { AddIcon, GeneralFormItem, GeneralFormRowOfItems, UsecaseButton } from '@lib/ui-components';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FinancialReportPeriod } from '@lib/api-interface';
import { BdoReportType } from '../../../libs/api-interface/models/report/bdo-report-type.enum';
import { useUserState } from '../../../state/UserState';
import { RolesEnum } from '../../../domain/enums/roles.enum';

export interface CreateReportButtonProps {
  onSaved: () => Promise<any>;
  reportType: BdoReportType;
}

export const CreateClientAdminBdoReportButton = ({ onSaved, reportType }: CreateReportButtonProps) => {
  const { t } = useTranslation();
  const reportTypeSpecificFilters: (GeneralFormItem | GeneralFormRowOfItems)[] = [];
  const userState = useUserState();
  const validationSchema = {};

  switch (reportType) {
    case BdoReportType.KPO_REPORT:
      if (userState.roles.includes(RolesEnum.REGIONAL_ADMIN)) {
        reportTypeSpecificFilters.push({
          rowItems: [
            {
              field: 'companyId',
              label: t('company'),
              type: 'table-select-by-api',
              tableSelectByApiParams: {
                apiPostEndpoint: '/api/client-admin/company/bdo/listByProjectIdForKpo',
                dialogTitle: t('company'),
                displayFormat: '{name}',
                selectedValueField: 'companyId',
                columns: [
                  {
                    label: t('companyName'),
                    key: 'name',
                    type: 'text',
                  },
                ],
              },
            },
          ],
        });
      }
      reportTypeSpecificFilters.push(
        {
          field: 'reportPeriod',
          label: t('reportPeriod'),
          type: 'select',
          selectOptions: Object.values(FinancialReportPeriod).map((val) => {
            return { label: t(`financialReportPeriod.${val}`), value: val };
          }),
        },
        {
          rowItems: [
            {
              field: 'startYearMonth',
              label: t('from'),
              type: 'date-year-month',
            },
            {
              field: 'endYearMonth',
              label: t('to'),
              type: 'date-year-month',
              isVisible: (data) => data.reportPeriod === FinancialReportPeriod.CUSTOM,
            },
          ],
        },
      );
      Object.assign(validationSchema, {
        reportPeriod: Yup.string().required(t('fieldRequired')),
        startYearMonth: Yup.string().required(t('fieldRequired')),
        endYearMonth: Yup.string().when('reportPeriod', {
          is: FinancialReportPeriod.CUSTOM,
          then: Yup.string()
            .required(t('fieldRequired'))
            .test('isAfterStart', t('datesMustBeInSameYear'), function (value) {
              const { startYearMonth } = this.parent;
              return startYearMonth && value ? new Date(startYearMonth).getFullYear() === new Date(value).getFullYear() : true;
            }),
        }),
      });
      break;
  }

  return (
    <UsecaseButton
      variant='contained'
      startIcon={<AddIcon />}
      onSaved={onSaved}
      groupName='reports'
      useCaseName='generate-report'
      buttonLabel={t('generateReport')}
      fields={[...reportTypeSpecificFilters]}
      hiddenValues={{ reportType }}
      validationSchema={validationSchema}
    />
  );
};
