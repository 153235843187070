import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiCallButton, DataTable } from '@lib/ui-components';
import { MASS_PRECISION } from '@lib/api-interface';

export const BdoErrorsDataTable = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  return (
      <DataTable
        refreshKey={tableReloadKey}
        fetchUrl={'/api/bdo-errors'}
        initialSort={{ columnIndex: 0, ascending: false }}
        columns={[
          {
            key: 'startedAt',
            label: t('problemOccurrence'),
            type: 'date-time',
          },
          {
            key: 'senderCompanyName',
            label: t('client'),
            type: 'text',
          },
          {
            key: 'branchName',
            label: t('branch'),
            type: 'text',
          },
          {
            key: 'planedTransportTime',
            label: t('plannedTransportTime'),
            type: 'date-time',
          },
          {
            key: 'transporter',
            label: t('transportingSubcontractor'),
            type: 'text',
          },
          {
            key: 'receiverKpo',
            label: t('receivingSubcontractor'),
            type: 'text',
          },
          {
            key: 'mass',
            label: t('mass'),
            type: 'numeric',
            numericPrecision: MASS_PRECISION,
          },
          {
            key: 'wasteCode',
            label: t('wasteCode'),
            type: 'text',
          },
          {
            key: 'reason',
            label: t('reason'),
            type: 'enum',
            enumValues: [
              { value: 'GENERAL_ERROR', label: t('GENERAL_ERROR') },
              { value: 'BDO_SETTINGS_NOT_VALID', label: t('BDO_SETTINGS_NOT_VALID') },
              { value: 'NO_BDO_KEYS', label: t('NO_BDO_KEYS') },
              { value: 'INVALID_DATE', label: t('INVALID_DATE') },
              { value: 'SENDER_COMPANY_HAS_NO_BDO_CONNECTION', label: t('SENDER_COMPANY_HAS_NO_BDO_CONNECTION') },
              { value: 'SENDER_BRANCH_HAS_NO_BDO_CONNECTION', label: t('SENDER_BRANCH_HAS_NO_BDO_CONNECTION') },
              {
                value: 'TRANSPORTER_COMPANY_HAS_NO_BDO_CONNECTION',
                label: t('TRANSPORTER_COMPANY_HAS_NO_BDO_CONNECTION'),
              },
              { value: 'RECEIVER_COMPANY_HAS_NO_BDO_CONNECTION', label: t('RECEIVER_COMPANY_HAS_NO_BDO_CONNECTION') },
              { value: 'RECEIVER_BRANCH_HAS_NO_BDO_CONNECTION', label: t('RECEIVER_BRANCH_HAS_NO_BDO_CONNECTION') },
            ],
          },
          {
            key: 'statusBdo',
            label: t('bdoStatus'),
            type: 'enum',
            enumValues: [
              { value: 'CARD_ACCEPTED', label: t('CARD_ACCEPTED') },
              { value: 'CARD_PLANNED', label: t('CARD_PLANNED') },
              { value: 'LINK_TO_SUBCONTRACTOR', label: t('LINK_TO_SUBCONTRACTOR') },
              { value: 'LINK_TO_SUBCONTRACTOR_API', label: t('LINK_TO_SUBCONTRACTOR_API') },
            ],
          },
        ]}

        rowOptions={[
          {
            renderer: (row) => (
              <ApiCallButton
                variant="contained"
                color="primary"
                url={`/api/bdo-errors/${row.id}/solve`}
                onCompleted={async () => {
                  setTableReloadKey(tableReloadKey + 1);
                }}
                buttonLabel={t('done')}
              />
            ),
          },
        ]}
      />
  );
};