import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  AddModelButton,
  DataTable,
  DataTableColumn,
  DeleteModelButton,
  EditModelButton,
  GeneralFormItem,
  GeneralFormRowOfItems,
  RenderIf,
  Snackbar,
  Toolbar,
  UsecaseButton,
} from '@lib/ui-components';
import { CompanyBranchViewModel, ProjectCompanyBranchModel } from '@lib/api-interface';
import * as Yup from 'yup';
import { DataResultsDto } from '@lib/common-sdk';
import { RolesEnum } from '../../../../../../domain/enums/roles.enum';
import { ResetAccountPasswordButton } from '../../../../../../components/reset-account-password-button/reset-account-password-button';
import { AuthApiService } from '../../../../../../services/auth.api.service';
import { SendMailIcon } from '../../../../../../libs/ui-components/components/action-buttons/SendMailIcon';

export interface ProjectAccountsProps {
  projectId: string;
}

export const ProjectAccounts = ({ projectId }: ProjectAccountsProps) => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [emailsSentMessageShown, setEmailsSentMessageShown] = React.useState<boolean>(false);

  const emailsSentMessage = React.useMemo(() => {
    return (
      <RenderIf true={emailsSentMessageShown}>
        <Snackbar message={t('accountActivationEmailSent')} severity='success' open={emailsSentMessageShown} onClose={() => setEmailsSentMessageShown(false)} />
      </RenderIf>
    );
  }, [emailsSentMessageShown]);

  const fields: (GeneralFormItem | GeneralFormRowOfItems)[] = [
    { label: t('fullName'), type: 'text', field: 'name' },
    { label: t('login'), type: 'text', field: 'login' },
    { label: t('email'), type: 'text', field: 'email' },
    { label: t('phoneNumber'), type: 'text', field: 'tags.phone' },
    {
      label: t('branch'),
      type: 'table-multi-select',
      field: 'assignedBranchesIds',
      tableSelectParams: {
        modelName: 'projectBranchViews',
        modelClass: ProjectCompanyBranchModel,
        displayFormat: '{companyBranchName}',
        dialogTitle: t('selectBranch'),
        removeHateoasPathFromResult: true,
        fetchFilters: { projectId: projectId, deleted: false },
        selectedValueField: 'branchId',
        columns: [
          {
            key: 'companyName',
            label: t('companyName'),
            type: 'text',
          },
          {
            key: 'companyBranchName',
            label: t('Model.CompanyBranch.name'),
            type: 'text',
          },
          {
            key: 'nip',
            label: t('nip'),
            type: 'text',
          },
        ],
      },
    },
    { label: t('comment'), type: 'text', field: 'tags.comment' },
    {
      label: t('delayedEmailNotification'),
      type: 'boolean',
      field: 'tags.delayedEmailNotification',
      defaultValue: true,
    },
  ];

  const columns: DataTableColumn[] = [
    {
      key: 'name',
      label: t('fullName'),
      type: 'text',
    },
    {
      key: 'login',
      label: t('login'),
      type: 'text',
    },
    {
      key: 'email',
      label: t('email'),
      type: 'text',
    },
    {
      key: 'tags.phone',
      label: t('phone'),
      type: 'text',
    },
    {
      key: 'tags.comment',
      label: t('comment'),
      type: 'text',
    },
    {
      key: 'active',
      label: t('accountActive'),
      type: 'boolean',
      align: 'center',
    },
    {
      key: 'tags.lastLogin',
      label: t('lastLogin'),
      type: 'date',
    },
    {
      key: 'tags.delayedEmailNotification',
      label: t('delayedEmailNotification'),
      type: 'boolean',
      align: 'center',
    },
  ];

  const validationSchema = {
    name: Yup.string().required(t('fieldRequired')),
    login: Yup.string().required(t('fieldRequired')),
    email: Yup.string().email(t('requiredValidEmail')).required(t('fieldRequired')),
    assignedBranchesIds: Yup.array().required(t('fieldRequired')).min(1, t('fieldRequired')),
  };

  return (
    <>
      <Toolbar
        right={[
          <UsecaseButton
            onSaved={async () => {
              setEmailsSentMessageShown(true);
              setTableReloadKey(tableReloadKey + 1);
            }}
            onBeforeSave={(data) => {
              data.projectId = projectId;
              data.roles = [RolesEnum.REGIONAL_ADMIN];
              return data;
            }}
            useCaseName={'SendActivationMailForBunchOfUsersUseCase'}
            groupName={'account'}
            buttonLabel={t('sendActivationMailToUsers')}
            startIcon={<SendMailIcon />}
          />,
          <AddModelButton
            buttonLabel={t('addUserAccount')}
            dialogTitle={t('addUserAccount')}
            modelName={'accounts'}
            fields={fields.map((field) => {
              if ('defaultValue' in field && !field.defaultValue) {
                return { ...field, defaultValue: undefined };
              }
              return field;
            })}
            validationSchema={validationSchema}
            onBeforeSave={(formData) => {
              const tags: any = {};
              tags.phone = formData.tags.phone;
              tags.comment = formData.tags.comment;
              tags.delayedEmailNotification = formData.tags.delayedEmailNotification;
              formData.tags = tags;
              formData.roles = [RolesEnum.CLIENT_ADMIN, RolesEnum.REGIONAL_ADMIN];
              formData.authorities = [];
              formData.password = 'xxx';
              formData.assignedProjectId = projectId;
              return formData;
            }}
            overrideSaveCall={async (payload: any) => {
              const result = await AuthApiService.createUser(payload);
              if ('httpStatus' in result) {
                return new DataResultsDto(true, result.httpStatus, undefined, undefined, result);
              }
              return new DataResultsDto(false, undefined, undefined, undefined, result);
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'accounts', search: 'byRoleAndProjectId' }}
        fetchFilters={{ role: RolesEnum.REGIONAL_ADMIN, projectId: projectId }}
        columns={columns}
        rowOptionsMinWidth='14rem'
        rowOptions={[
          { renderer: (row) => <ResetAccountPasswordButton login={row.login} /> },
          {
            renderer: (row) => {
              return (
                <EditModelButton
                  id={row['id']}
                  entity={row}
                  buttonLabel={t('edit')}
                  dialogTitle={t('edit')}
                  modelName={'accounts'}
                  fields={fields.map((field) => ({
                    ...field,
                    defaultValue:
                      'field' in field && field.field === 'assignedBranchesIds'
                        ? row['assignedCompanyBranches'].map((companyBranch: CompanyBranchViewModel) => companyBranch.branchId)
                        : row['field' in field ? field.field : ''],
                    initialDisplayedValues: row['assignedCompanyBranches'].map((companyBranch: CompanyBranchViewModel) => companyBranch.name).join(', '),
                    initialValues: row['assignedCompanyBranches'].map((companyBranch: CompanyBranchViewModel) => companyBranch.branchId),
                  }))}
                  validationSchema={validationSchema}
                  onBeforeSave={(formData) => {
                    const tags: any = row.tags;
                    tags.phone = formData.tags.phone;
                    tags.comment = formData.tags.comment;
                    tags.delayedEmailNotification = formData.tags.delayedEmailNotification;
                    formData.id = row.id;
                    formData.tags = tags;
                    return formData;
                  }}
                  overrideSaveCall={async (payload: any) => {
                    const result = await AuthApiService.updateUser(payload);
                    console.log(result);
                    if (result && 'httpStatus' in result) {
                      return new DataResultsDto(true, result.httpStatus, undefined, undefined, result);
                    }
                    return new DataResultsDto(false, undefined, undefined, undefined, result);
                  }}
                  onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                  yellowIcon
                />
              );
            },
          },
          {
            renderer: (row) => <DeleteModelButton id={row.id} modelName='accounts' onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
      {emailsSentMessage}
    </>
  );
};
