import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import styles from './styles.module.css';
import { FormInputText } from '../form-input-text';
import { FormInputCheckbox } from '../form-input-checkbox';
import { FormInputDropdown, FormInputDropdownOption } from '../form-input-dropdown';
import { FormInputMultiDropdown, FormInputMultiDropdownOption } from '../form-input-multi-dropdown';
import { FormInputNumeric } from '../form-input-numeric';
import { FormInputDate } from '../form-input-date';
import { Button } from '../../button/button';
import { Snackbar } from '../../snackbar/snack-bar';
import { useNavigate } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { FormInputLongNumeric } from '../form-input-long-numeric';
import { FormInputTableSelect } from '../form-input-table-select';
import { ClassConstructor } from 'class-transformer';
import { DataTableColumn } from '../../data-table/data-table';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useTheme } from '@mui/material';
import { FormInputFile } from '../form-input-file';
import { AddIcon } from '../../action-buttons';
import styled, { css } from 'styled-components';
import { FormInputTableSelectByApi } from '../form-input-table-select-by-api';
import { FormInputChips } from '../form-input-chips';
import { useDebouncedCallback } from 'use-debounce';
import { isTrue } from '../../../../../pages/organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';
import { FormInputTableMultiSelect } from '../form-input-table-multi-select';
import { RenderIf } from '../../render-if';

interface StyledFieldProps {
  width?: string;
  margin?: string;
  isDeactivated?: boolean;
}

const StyledField = styled.div<StyledFieldProps>`
  width: ${({ width }) => (width ? width : '100%')};
  margin: ${({ margin }) => margin};
  ${({ isDeactivated }) =>
    isDeactivated &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}
`;

export interface GeneralFormSelectOption {
  label: string;
  value: string;
}

export interface GeneralFormTableSelectParams {
  displayFormat: string; // ex '{firstName} {lastName}'
  dialogTitle: string;
  modelName: string;
  search?: string;
  modelClass?: ClassConstructor<any>;
  selectedValueField?: string; // _id by default
  fetchFilters?: any;
  columns: DataTableColumn[];
  removeHateoasPathFromResult?: boolean; // false by default
  otherValuesMap?: any;
}

export interface GeneralFormTableSelectByApiParams {
  displayFormat: string; // ex '{firstName} {lastName}'
  dialogTitle: string;
  selectedValueField?: string; // _id by default
  apiPostEndpoint: string;
  apiPostParams?: any;
  columns: DataTableColumn[];
  initialEntity?: any;
  onRowSelected?: (row: any) => void;
  otherValuesMap?: any;
  queryFields?: (GeneralFormItem | GeneralFormRowOfItems)[];
  autoSelectFirstOptionOnInitIfNoInitialEntity?: boolean;
  shouldAutoSelectFirst?: (apiParams: any) => boolean;
  shouldClearOnApiParams?: (apiParams: any) => boolean;
}

export interface GeneralFormItem {
  field: string;
  label: string;
  helperText?: string;
  type:
    | 'text'
    | 'boolean'
    | 'select'
    | 'numeric'
    | 'long-numeric'
    | 'date'
    | 'date-year-month'
    | 'date-year'
    | 'table-select'
    | 'table-multi-select'
    | 'header'
    | 'header-icon'
    | 'file'
    | 'multi-select'
    | 'table-select-by-api'
    | 'chips';
  selectOptions?: GeneralFormSelectOption[];
  onSelect?: (value: any) => void;
  tableSelectParams?: GeneralFormTableSelectParams;
  tableSelectByApiParams?: GeneralFormTableSelectByApiParams;
  defaultValue?: any;
  numericPrecision?: number;
  numericMinValue?: number;
  numericMaxValue?: number;
  isVisible?: ((data: any) => boolean) | boolean;
  width?: string;
  margin?: string;
  isDeactivated?: ((data: any) => boolean) | boolean;
  clearableDate?: boolean;
  minDate?: Date;
  maxDate?: Date;
  testId?: string;
  resetField?: ((data: any) => boolean) | boolean;
  initialValues?: any[];
  initialDisplayedValues?: string;
}

export interface GeneralFormRowOfItems {
  rowItems: GeneralFormItem[];
  rowItemsJustify?: 'space-between' | 'left' | 'right' | 'center';
  isVisible?: (data: any) => boolean;
}

export interface GeneralFormProps {
  beforeSave?: (data: any) => void;
  onSaved?: (data: any) => Promise<any>;
  onCanceled?: () => Promise<any>;
  onChanged?: (data: any) => void;
  validationSchema?: any;
  fields: (GeneralFormItem | GeneralFormRowOfItems)[];
  hideSubmitButton?: boolean;
  submitButtonLabel?: string;
  hideCancelButton?: boolean;
  cancelButtonLabel?: string;
  width?: string;
  hiddenValues?: any;
  variant?: 'standard' | 'outlined' | 'filled';
  backInsteadOfCancelButton?: boolean;
  additionalFormButtons?: Array<JSX.Element>;
}

interface KeyValueObject {
  [key: string]: any;
}

function convertToNestedObject(obj: Record<string, any>): KeyValueObject {
  return Object.keys(obj).reduce((acc: KeyValueObject, key: string) => {
    const propPath = key.split('.');
    let nestedObj = acc;

    for (let i = 0; i < propPath.length - 1; i++) {
      const propName = propPath[i];

      nestedObj[propName] = nestedObj[propName] || {};
      nestedObj = nestedObj[propName];
    }

    nestedObj[propPath[propPath.length - 1]] = obj[key];

    return acc;
  }, {});
}

function mergeObjects(obj1: KeyValueObject, obj2: KeyValueObject): KeyValueObject {
  const mergedObj = { ...obj1 };
  Object.keys(obj2).forEach((key) => {
    if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
      mergedObj[key] = Object.assign({}, mergedObj[key], obj2[key]);
    } else {
      mergedObj[key] = obj2[key];
    }
  });
  return mergedObj;
}

function getDefaultValues(fields: (GeneralFormItem | GeneralFormRowOfItems)[]) {
  function setDefaultForField(field: GeneralFormItem) {
    if (field.type === 'text' || field.type === 'numeric' || field.type === 'long-numeric' || field.type === 'table-select' || field.type === 'table-select-by-api') {
      result[field.field] = field.defaultValue != null ? field.defaultValue : '';
    }
    if (field.type === 'boolean') {
      result[field.field] = field.defaultValue != null ? field.defaultValue : false;
    }
    if (field.type === 'select' || field.type === 'multi-select') {
      if (field.selectOptions && field.selectOptions.length > 0) {
        result[field.field] = field.defaultValue != null ? field.defaultValue : field.selectOptions[0].value;
      }
    }
    if (field.type === 'table-multi-select') {
      result[field.field] = field.defaultValue != null ? field.defaultValue : [];
    }
    if (field.type === 'date' || field.type === 'date-year-month' || field.type === 'date-year') {
      result[field.field] = field.defaultValue != null ? field.defaultValue : '';
    }
    if (field.type === 'chips') {
      result[field.field] = field.defaultValue != null ? field.defaultValue : [];
    }
  }

  const result: any = {};
  fields.forEach((field) => {
    if ('rowItems' in field) {
      field.rowItems.forEach((rowItem) => {
        setDefaultForField(rowItem);
      });
    } else {
      setDefaultForField(field);
    }
  });
  return result;
}

export const GeneralForm = (props: GeneralFormProps) => {
  // to check if component is mounted / unmounted
  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();
  const [generalFailure, setGeneralFailure] = useStateIfMounted<any>(null);
  const [additionalValuesFromTableSelect, setAdditionalValuesFromTableSelect] = useStateIfMounted<any>({});
  const currentRenderAdditionalValuesFromTableSelect = additionalValuesFromTableSelect;
  const [visibilityMap, setVisibilityMap] = useStateIfMounted<any>({});
  const validationSchema = Yup.object().shape(props.validationSchema);
  const defaultValues = getDefaultValues(props.fields);

  const methods = useForm<any>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { handleSubmit, trigger, control, reset, resetField, setValue, watch, formState, clearErrors } = methods;

  const watchAll = watch();
  const allValuesResult = React.useMemo(() => {
    const val = { ...watchAll, ...additionalValuesFromTableSelect };
    return { values: val, hash: JSON.stringify(val) };
  }, [watchAll, additionalValuesFromTableSelect]);
  const allValues = allValuesResult.values;

  const debouncedChangedValue = useDebouncedCallback((values) => {
    async function callCallback() {
      await trigger();
      if (props.onChanged && formState.isValid) {
        props.onChanged(allValues);
      }
    }

    if (isMounted.current) {
      callCallback();
    }
  }, 150);

  React.useEffect(() => {
    debouncedChangedValue(watchAll);
  }, [allValuesResult.hash]);

  async function handleSave(data: any) {
    const additionalValues: any = additionalValuesFromTableSelect != null ? convertToNestedObject(additionalValuesFromTableSelect) : {};
    const valuesToSave = mergeObjects(data, additionalValues);
    try {
      Object.keys(valuesToSave)
        .filter((key) => key.includes('.'))
        .forEach((key) => delete valuesToSave[key]);

      if (props.beforeSave) {
        props.beforeSave(valuesToSave);
      }
      if (props.onSaved) {
        await props.onSaved(valuesToSave);
      }
    } catch (e) {
      setGeneralFailure(e);
    }
  }

  async function handleCancel() {
    try {
      if (props.onCanceled) {
        await props.onCanceled();
      }
      if (isMounted.current) {
        reset();
      }
    } catch (e) {
      setGeneralFailure(e);
    }
  }

  function mapValuesFromSelectedTableInput(otherValuesMap: any, row: any) {
    if (otherValuesMap != null) {
      let modified = false;
      Object.keys(otherValuesMap).forEach((key) => {
        const mappedKey = otherValuesMap[key];
        if (row != null && row[key] != null) {
          if (currentRenderAdditionalValuesFromTableSelect[mappedKey] !== row[key]) {
            modified = true;
            currentRenderAdditionalValuesFromTableSelect[mappedKey] = row[key];
          }
        } else {
          if (currentRenderAdditionalValuesFromTableSelect[mappedKey] !== undefined) {
            modified = true;
            delete currentRenderAdditionalValuesFromTableSelect[mappedKey];
          }
        }
      });
      if (modified) {
        setAdditionalValuesFromTableSelect(currentRenderAdditionalValuesFromTableSelect);
      }
    }
  }

  function mapGeneralFormItemToComponent(item: GeneralFormItem, key?: string) {
    if (item.isVisible && !isTrue(item.isVisible, allValues)) return undefined;
    if (item.isVisible && !isTrue(item.isVisible, allValues)) {
      if (visibilityMap[item.field] !== undefined) {
        delete visibilityMap[item.field];
        setVisibilityMap({ ...visibilityMap });
      }
      return undefined;
    } else if (visibilityMap[item.field] === undefined) {
      visibilityMap[item.field] = true;
      setVisibilityMap({ ...visibilityMap });
    }
    const isDeactivated = isTrue(item.isDeactivated, allValues);
    const resetField = isTrue(item.resetField, allValues);

    if (item.type === 'text') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputText
            name={item.field}
            control={control}
            label={item.label}
            variant={props.variant}
            isDeactivated={isDeactivated}
            helperText={item.helperText}
            testId={item.testId}
          />
        </StyledField>
      );
    }

    if (item.type === 'boolean') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputCheckbox
            name={item.field}
            control={control}
            label={item.label}
            initialValue={item.defaultValue}
            setValue={setValue}
            isDeactivated={isDeactivated}
            testId={item.testId}
          />
        </StyledField>
      );
    }

    if (item.type === 'select') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputDropdown
            name={item.field}
            control={control}
            label={item.label}
            options={item.selectOptions as FormInputDropdownOption[]}
            variant={props.variant}
            isDeactivated={isDeactivated}
            testId={item.testId}
            onSelect={item.onSelect}
          />
        </StyledField>
      );
    }

    if (item.type === 'multi-select') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputMultiDropdown
            name={item.field}
            control={control}
            label={item.label}
            options={item.selectOptions as FormInputMultiDropdownOption[]}
            variant={props.variant}
            isDeactivated={isDeactivated}
            testId={item.testId}
          />
        </StyledField>
      );
    }

    if (item.type === 'numeric') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputNumeric
            name={item.field}
            control={control}
            label={item.label}
            variant={props.variant}
            precision={item.numericPrecision}
            max={item.numericMaxValue}
            min={item.numericMinValue}
            isDeactivated={isDeactivated}
            testId={item.testId}
          />
        </StyledField>
      );
    }

    if (item.type === 'long-numeric') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputLongNumeric
            name={item.field}
            control={control}
            label={item.label}
            variant={props.variant}
            precision={item.numericPrecision}
            max={item.numericMaxValue}
            min={item.numericMinValue}
            initialValue={item.defaultValue}
            setValue={setValue}
            isDeactivated={isDeactivated}
            testId={item.testId}
          />
        </StyledField>
      );
    }

    if (item.type === 'date') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputDate
            name={item.field}
            control={control}
            label={item.label}
            variant={props.variant}
            isDeactivated={isDeactivated}
            clearableDate={item.clearableDate}
            testId={item.testId}
            minDate={item.minDate}
            maxDate={item.maxDate}
          />
        </StyledField>
      );
    }

    if (item.type === 'date-year-month') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputDate
            name={item.field}
            control={control}
            label={item.label}
            variant={props.variant}
            dateVariant='year-month'
            isDeactivated={isDeactivated}
            clearableDate={item.clearableDate}
            testId={item.testId}
            minDate={item.minDate}
            maxDate={item.maxDate}
          />
        </StyledField>
      );
    }

    if (item.type === 'date-year') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputDate
            name={item.field}
            control={control}
            label={item.label}
            variant={props.variant}
            dateVariant='year'
            isDeactivated={isDeactivated}
            clearableDate={item.clearableDate}
            testId={item.testId}
            minDate={item.minDate}
            maxDate={item.maxDate}
          />
        </StyledField>
      );
    }

    if (item.type === 'table-select') {
      if (item.tableSelectParams === undefined) {
        throw new Error('table-select column type without tableSelectParams');
      }

      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputTableSelect
            modelName={item.tableSelectParams.modelName}
            displayFormat={item.tableSelectParams.displayFormat}
            name={item.field}
            search={item.tableSelectParams.search}
            control={control}
            label={item.label}
            variant={props.variant}
            dialogTitle={item.tableSelectParams.dialogTitle}
            fetchFilters={item.tableSelectParams.fetchFilters}
            initialId={item.defaultValue}
            columns={item.tableSelectParams.columns}
            modelClass={item.tableSelectParams.modelClass}
            selectedValueField={item.tableSelectParams.selectedValueField}
            setValue={setValue}
            clearErrors={clearErrors}
            removeHateoasPathFromResult={item.tableSelectParams.removeHateoasPathFromResult}
            onRowSelected={(row) => mapValuesFromSelectedTableInput(item.tableSelectParams?.otherValuesMap, row)}
            getFormValues={getCurrentValues}
            isDeactivated={isDeactivated}
            testId={item.testId}
            initialDisplayValue={item.initialDisplayedValues}
          />
        </StyledField>
      );
    }

    if (item.type === 'table-multi-select') {
      if (item.tableSelectParams !== undefined) {
        return (
          <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
            <FormInputTableMultiSelect
              modelName={item.tableSelectParams.modelName}
              displayFormat={item.tableSelectParams.displayFormat}
              name={item.field}
              search={item.tableSelectParams.search}
              control={control}
              label={item.label}
              variant={props.variant}
              dialogTitle={item.tableSelectParams.dialogTitle}
              fetchFilters={item.tableSelectParams.fetchFilters}
              columns={item.tableSelectParams.columns}
              modelClass={item.tableSelectParams.modelClass}
              selectedValueField={item.tableSelectParams.selectedValueField}
              setValue={setValue}
              clearErrors={clearErrors}
              getFormValues={getCurrentValues}
              isDeactivated={isDeactivated}
              testId={item.testId}
              initialIds={item.initialValues}
              initialDisplayedValues={item.initialDisplayedValues}
            />
          </StyledField>
        );
      }
      if (item.tableSelectByApiParams !== undefined) {
        return (
          <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
            <FormInputTableMultiSelect
              fetchPostUrl={item.tableSelectByApiParams.apiPostEndpoint}
              displayFormat={item.tableSelectByApiParams.displayFormat}
              name={item.field}
              control={control}
              label={item.label}
              variant={props.variant}
              dialogTitle={item.tableSelectByApiParams.dialogTitle}
              fetchFilters={item.tableSelectByApiParams.apiPostParams}
              columns={item.tableSelectByApiParams.columns}
              selectedValueField={item.tableSelectByApiParams.selectedValueField}
              setValue={setValue}
              clearErrors={clearErrors}
              getFormValues={getCurrentValues}
              isDeactivated={isDeactivated}
              testId={item.testId}
              initialIds={item.initialValues}
              initialDisplayedValues={item.initialDisplayedValues}
            />
          </StyledField>
        );
      }
      throw new Error('table-multi-select column type without tableSelectParams or tableSelectByApiParams');
    }

    if (item.type === 'table-select-by-api') {
      if (item.tableSelectByApiParams === undefined) {
        throw new Error('table-select column type without tableSelectByApiParams');
      }

      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputTableSelectByApi
            apiPostEndpoint={item.tableSelectByApiParams.apiPostEndpoint}
            apiPostParams={item.tableSelectByApiParams.apiPostParams}
            displayFormat={item.tableSelectByApiParams.displayFormat}
            name={item.field}
            control={control}
            label={item.label}
            variant={props.variant}
            dialogTitle={item.tableSelectByApiParams.dialogTitle}
            columns={item.tableSelectByApiParams.columns}
            setValue={setValue}
            clearErrors={clearErrors}
            initialEntity={item.tableSelectByApiParams.initialEntity}
            selectedValueField={item.tableSelectByApiParams.selectedValueField}
            onRowSelected={(row) => {
              mapValuesFromSelectedTableInput(item.tableSelectByApiParams?.otherValuesMap, row);
              if (item.tableSelectByApiParams?.onRowSelected !== undefined) {
                item.tableSelectByApiParams.onRowSelected(row);
              }
            }}
            queryFields={item.tableSelectByApiParams.queryFields}
            otherValuesMap={item.tableSelectByApiParams.otherValuesMap}
            getFormValues={getCurrentValues}
            isDeactivated={isDeactivated}
            autoSelectFirstOptionOnInitIfNoInitialEntity={item.tableSelectByApiParams.autoSelectFirstOptionOnInitIfNoInitialEntity}
            shouldAutoSelectFirst={item.tableSelectByApiParams.shouldAutoSelectFirst}
            shouldClearOnApiParams={item.tableSelectByApiParams.shouldClearOnApiParams}
            testId={item.testId}
            resetField={resetField}
          />
        </StyledField>
      );
    }

    if (item.type === 'file') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputFile
            name={item.field}
            control={control}
            label={item.label}
            variant={props.variant}
            setValue={setValue}
            resetField={resetField}
            isDeactivated={isDeactivated}
            testId={item.testId}
          />
        </StyledField>
      );
    }

    if (item.type === 'header') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <div className={styles['header']} style={{ color: theme.palette.primary.main }}>
            {item.label}
          </div>
        </StyledField>
      );
    }

    if (item.type === 'header-icon') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <div className={styles['header-icon']} style={{ color: theme.palette.primary.main }}>
            <div style={{ textAlign: 'center' }}>
              <AddIcon />
            </div>
            {item.label}
          </div>
        </StyledField>
      );
    }

    if (item.type === 'chips') {
      return (
        <StyledField key={key} width={item.width} margin={item.margin} isDeactivated={isDeactivated}>
          <FormInputChips
            name={item.field}
            control={control}
            label={item.label}
            variant={props.variant}
            precision={item.numericPrecision}
            max={item.numericMaxValue}
            min={item.numericMinValue}
            initialValue={item.defaultValue}
            placeholderLabel={t('enterValueThenPressEnter')}
            isDeactivated={isDeactivated}
            testId={item.testId}
          />
        </StyledField>
      );
    }

    return undefined;
  }

  const inputControls = props.fields.map((item, index) => {
    if ('rowItems' in item) {
      return (
        <div
          key={index}
          className={styles['formRowContainer']}
          style={{
            justifyContent: item.rowItemsJustify != null ? item.rowItemsJustify : 'left',
          }}
        >
          {item.rowItems.map((rowItem, rowItemIndex) => {
            return mapGeneralFormItemToComponent(rowItem, `${index}_${rowItemIndex}`);
          })}
        </div>
      );
    } else {
      return mapGeneralFormItemToComponent(item, `${index}`);
    }
  });

  const hideButtons = props.hideSubmitButton === true && props.hideCancelButton === true;

  function getCurrentValues(): any {
    return allValues;
  }

  return (
    <div className={styles['formContainer']} style={{ width: props.width }}>
      {inputControls}
      {!hideButtons && (
        <div className={styles['formButtonsGridContainer']}>
          <div className={styles['formButtonsCenterItems']}>
            {props.hideCancelButton !== true &&
              (props.backInsteadOfCancelButton === true ? (
                <Button label={t('Common.back')} color='primary' variant='text' startIcon={<NavigateBeforeIcon />} onClick={async () => navigate(-1)} />
              ) : (
                <Button
                  label={props.cancelButtonLabel != null ? props.cancelButtonLabel : t('Common.cancel')}
                  color='primary'
                  variant='text'
                  onClick={async () => handleCancel()}
                />
              ))}
            {!props.hideSubmitButton && (
              <Button
                label={props.submitButtonLabel != null ? props.submitButtonLabel : t('save')}
                color='secondary'
                variant='contained'
                isDisabled={!formState.isValid}
                onClick={async () => handleSubmit(handleSave)()}
              />
            )}
          </div>
          <RenderIf true={!!props.additionalFormButtons}>
            <div
              className={styles['formButtonsRightItems']}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                return handleCancel();
              }}
            >
              {props.additionalFormButtons}
            </div>
          </RenderIf>
        </div>
      )}
      <Snackbar open={generalFailure !== null} message={generalFailure} severity='error' onClose={() => setGeneralFailure(null)} />
    </div>
  );
};
