import { Tabs } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { FinancialReports } from './financial/financial-reports';
import { BdoReports } from './bdo/bdo-reports';
import { MonthEndClosingReports } from './month-end-closing/month-end-closing-reports';
import { LoginReports } from './login/login-reports';
import { OtherReports } from './other/other-reports';
import { useUserState } from '../../../state/UserState';
import { RolesEnum } from '../../../domain/enums/roles.enum';
import * as React from 'react';
import { ProjectSettings } from '@lib/api-interface';
import axios from 'axios';

export const Reports = () => {
  const { t } = useTranslation();
  const userState = useUserState();
  const isClient = userState.roles.includes(RolesEnum.CLIENT_ADMIN) || userState.roles.includes(RolesEnum.CLIENT_USER);
  const isContractor = userState.roles.includes(RolesEnum.CONTRACTOR);

  if (isContractor) {
    return <Tabs panels={[{ label: t('financialReports'), component: <FinancialReports /> }]} />;
  }
  if (isClient) {
    const [projectSettings, setProjectSettings] = React.useState<ProjectSettings | null>(null);

    React.useEffect(() => {
      const fetchSettings = async () => {
        const url = `${process.env['REACT_APP_API_URL']}/api/project-branch-view/settings`;
        try {
          const response = await axios.get(url);
          setProjectSettings(response.data);
        } catch (error) {
          console.error('Failed to fetch settings:', error);
          setProjectSettings(null);
        }
      };
      fetchSettings();
    }, []);

    const panels = [];
    if (projectSettings?.branchCanGenerateReports) {
      panels.push({ label: t('financialReports'), component: <FinancialReports /> });
    }
    if (projectSettings?.branchCanGenerateBdoReports) {
      panels.push({ label: t('bdoReports'), component: <BdoReports /> });
    }
    return panels.length > 0 ? <Tabs panels={panels} /> : null;
  }

  return (
    <Tabs
      panels={[
        { label: t('financialReports'), component: <FinancialReports /> },
        { label: t('bdoReports'), component: <BdoReports /> },
        { label: t('monthEndClosingReports'), component: <MonthEndClosingReports /> },
        { label: t('loginReports'), component: <LoginReports /> },
        { label: t('otherReports'), component: <OtherReports /> },
      ]}
    />
  );
};
