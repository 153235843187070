import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DataTable, DataTableColumn, Dialog, GeneralFormItem, GeneralFormRowOfItems, UsecaseButton } from '@lib/ui-components';
import { priceBearerEnumValues, priceFields, priceFieldsValidation, priceTypeEnumValues } from './shared';
import * as Yup from 'yup';
import React from 'react';
import { TFunction } from 'react-i18next';
import { ServiceType, ServiceViewDetails } from '@lib/api-interface';

export interface ServicePricesDialogProps {
  service: ServiceViewDetails;
  t: TFunction<'common', undefined>;
  openDialog: boolean;
  handleCloseDialog?: React.MouseEventHandler<HTMLButtonElement>;
  onSaved: () => Promise<any>;
}

export const ServicePricesDialog = ({ service, t, openDialog, handleCloseDialog, onSaved }: ServicePricesDialogProps) => {
  const columns: DataTableColumn[] = [{ key: 'activeFromDate', label: t('priceFrom'), type: 'date' }];
  const fields: (GeneralFormItem | GeneralFormRowOfItems)[] = [];
  if (service.serviceType === ServiceType.ADDITIONAL && service.constantCost === true) {
    fields.push({
      field: 'activeFromMonth',
      label: t('priceFrom'),
      type: 'date-year-month',
    });
  } else {
    fields.push({
      field: 'activeFromDate',
      label: t('priceFrom'),
      type: 'date',
    });
  }
  if (service.serviceType === ServiceType.SCHEDULED || service.serviceType === ServiceType.ADDITIONAL) {
    columns.push({ key: 'pix', label: t('pix'), type: 'boolean' });
    fields.push({ field: 'pix', label: t('pix'), type: 'boolean', defaultValue: service?.currentSettings?.pix });
  }
  if (service.serviceType === ServiceType.COMMUNE) {
    columns.push(
      {
        key: 'communePrice.stringValue',
        label: t('containerPrice'),
        type: 'text',
      },
      { key: 'declaredPrice', label: t('declaredPrice'), type: 'text' },
    );
    fields.push(
      ...priceFields('communePrice', t, t('containerPrice'), {
        initialService: service,
        bearerVisible: false,
        fixedPriceType: true,
        defaultType: 'UNIT',
      }),
      {
        field: 'declaredPrice',
        label: t('declaredPrice'),
        type: 'numeric',
        numericPrecision: 2,
        defaultValue: service.declaredPrice,
      },
    );
  } else {
    columns.push({ key: 'organizationAsSide', label: t('organizationAsSide'), type: 'boolean' });
    fields.push({
      field: 'organizationAsSide',
      label: t('organizationAsSide'),
      type: 'boolean',
      defaultValue: service?.currentSettings?.organizationAsSide,
    });
    if (service.serviceType !== ServiceType.ADDITIONAL) {
      columns.push(
        { key: 'clientTransportPrice.price', label: t('clientTransport'), type: 'numeric', numericPrecision: 2 },
        {
          key: 'subcontractorTransportPrice.price',
          label: t('subcontractorTransport'),
          type: 'numeric',
          numericPrecision: 2,
        },
      );
      fields.push(
        ...priceFields('clientTransportPrice', t, t('clientTransport'), {
          initialService: service,
          fixedBearer: true,
          fixedPriceType: true,
        }),
        ...priceFields('subcontractorTransportPrice', t, t('subcontractorTransport'), {
          initialService: service,
          fixedBearer: true,
          fixedPriceType: true,
        }),
      );
    }
    columns.push(
      { key: 'clientCollectionPrice.price', label: t('clientCollection'), type: 'numeric', numericPrecision: 2 },
      {
        key: 'clientCollectionPrice.priceType',
        label: t('priceType'),
        type: 'enum',
        enumValues: priceTypeEnumValues(t),
      },
      {
        key: 'clientCollectionPrice.bearer',
        label: t('revenueOrIS'),
        type: 'enum',
        enumValues: priceBearerEnumValues(t),
      },
    );
    if (service.serviceType !== ServiceType.ADDITIONAL) {
      columns.push(
        {
          key: 'clientCollectionPrice.navResource.name',
          label: t('navResourceName'),
          type: 'text',
        },
        {
          key: 'clientCollectionPrice.navResource.code',
          label: t('navResourceNumber'),
          type: 'text',
        },
      );
    }
    columns.push(
      {
        key: 'subcontractorCollectionPrice.price',
        label: t('subcontractorCollection'),
        type: 'numeric',
        numericPrecision: 2,
      },
      {
        key: 'subcontractorCollectionPrice.priceType',
        label: t('priceType'),
        type: 'enum',
        enumValues: priceTypeEnumValues(t),
      },
      {
        key: 'subcontractorCollectionPrice.bearer',
        label: t('revenueOrIS'),
        type: 'enum',
        enumValues: priceBearerEnumValues(t),
      },
    );
    if (service.serviceType !== ServiceType.ADDITIONAL) {
      columns.push(
        {
          key: 'subcontractorCollectionPrice.navResource.name',
          label: t('navResourceName'),
          type: 'text',
        },
        {
          key: 'subcontractorCollectionPrice.navResource.code',
          label: t('navResourceNumber'),
          type: 'text',
        },
      );
    }
    fields.push(
      ...priceFields('clientCollectionPrice', t, t('clientCollection'), {
        initialService: service,
        navResourceVisible: () => service.serviceType !== ServiceType.ADDITIONAL,
        isVisible: (data) => !data.pix,
        type: service.serviceType,
        fixedPriceType: true,
      }),
      ...priceFields('subcontractorCollectionPrice', t, t('subcontractorCollection'), {
        initialService: service,
        navResourceVisible: () => service.serviceType !== ServiceType.ADDITIONAL,
        type: service.serviceType,
        fixedPriceType: true,
      }),
    );
  }

  const validationSchema: any = {
    ...priceFieldsValidation(t, service.serviceType!),
  };

  if (service.serviceType === ServiceType.ADDITIONAL && service.constantCost === true) {
    validationSchema.activeFromMonth = Yup.string().required(t('fieldRequired'));
  } else {
    validationSchema.activeFromDate = Yup.mixed()
      .test('activeFromDate', t('fieldRequired'), function (value) {
        return value;
      })
      .test('activeFromDate', t('dateCannotBeBeforeActivationDate'), function (value) {
        const activationDateTimestamp = new Date(service.activationDate!);
        const deactivationDateTimestamp = new Date(value);
        return deactivationDateTimestamp >= activationDateTimestamp;
      });
  }

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth={false} fullWidth={true}>
      <DialogTitle sx={{ margin: 'auto' }}>
        <IconButton
          aria-label='close'
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DataTable refreshKey={Date.now()} data={service.settings} columns={columns} />
        <UsecaseButton
          useCaseName='AddSettingsToServiceUseCase'
          groupName='service'
          hiddenValues={{ serviceId: service.serviceId! }}
          buttonLabel={t('changePrices')}
          fields={fields}
          validationSchema={validationSchema}
          onSaved={onSaved}
          onBeforeSave={(formData) => {
            if (service.serviceType === ServiceType.ADDITIONAL && service.constantCost === true) {
              formData.activeFromDate = `${formData.activeFromMonth}-01`;
            }
            return formData;
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
