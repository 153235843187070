import * as React from 'react';
import { useEffect, useState } from 'react';
import { PublicRoutes } from '../../pages/public/public-routes';
import { getInitialLocation, useUserState } from '../../state/UserState';
import { TechnicalAdminRoutes } from '../../pages/technical-admin/technical-admin-routes';
import { OrganizationRoutes } from '../../pages/organization/organization-routes';
import { AuthApiService } from '../../services/auth.api.service';
import LinearProgress from '@mui/material/LinearProgress';
import styles from './styles.module.css';
import { RolesEnum } from '../../domain/enums/roles.enum';
import { useNavigate } from 'react-router-dom';
import { ClientAdminRoutes } from '../../pages/client-admin/client-admin-routes';
import { ClientUserRoutes } from '../../pages/client-user/client-user-routes';
import { ContractorRoutes } from '../../pages/contractor/contractor-routes';

export const RouteSelector = () => {
  const userState = useUserState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // if unlogged -> PublicRoutes
  // if interseroh-admin -> InterserohAdminRoutes
  // if subcontractor -> SubcontractorRoutes
  // if clientAdmin -> ClientAdminRoutes
  // if client -> ClientRoutes

  useEffect(() => {
    if (!userState.isPresent && !loading && userState.canAskForRefreshToken) {
      setLoading(true);
      AuthApiService.tryRefreshToken()
        .then((result) => {
          if ('token' in result) {
            userState.setUser(result.token, result.refreshToken);
            navigate(getInitialLocation());
          } else {
            userState.setUser('', '-');
          }
          setLoading(false);
        })
        .catch((reason) => {
          userState.setUser('', '-');
          setLoading(false);
        });
    }
  }, [userState]);

  if (loading) {
    return (
      <div className={styles['progressContainer']}>
        <LinearProgress />
      </div>
    );
  }

  if (!userState.isPresent) {
    return <PublicRoutes />;
  }

  if (userState.roles.length === 0) {
    return <div>User without assigned role</div>;
  }

  switch (userState.roles[0]) {
    case RolesEnum.TECHNICAL_ADMIN:
      return <TechnicalAdminRoutes />;
    case RolesEnum.ORGANIZATION:
      return <OrganizationRoutes />;
    case RolesEnum.CONTRACTOR:
      return <ContractorRoutes />;
    case RolesEnum.CLIENT_ADMIN:
      return <ClientAdminRoutes />;
    case RolesEnum.CLIENT_USER:
      return <ClientUserRoutes />;
    default:
      return <div>User role {userState.roles} not recognized</div>;
  }
};
