import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormInputProps } from './form-input-props';
import { HelperText } from './helper-text';

export const FormInputText = ({ name, control, label, variant, labelColor, isDeactivated, helperText }: FormInputProps) => {
  if (variant == null) {
    variant = 'outlined';
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <div data-test-id={`input-${name}-wrapper`}>
          <TextField
            inputProps={{ 'data-test-id': `input-${name}` }}
            helperText={error ? error.message : helperText ? helperText : ' '}
            error={!!error}
            size='small'
            onChange={onChange}
            value={value}
            fullWidth
            label={label}
            variant={variant}
            margin='normal'
            sx={{ color: labelColor }}
            disabled={isDeactivated}
          />
          {helperText && error && <HelperText>{helperText}</HelperText>}
        </div>
      )}
    />
  );
};
