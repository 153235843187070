import * as React from 'react';
import { UserState, useUserState } from '../../state/UserState';
import styles from './styles.module.css';
import { TFunction, useTranslation } from 'react-i18next';
import { LeftMenuLink } from '../left-menu-link/left-menu-link';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BarChart from '@mui/icons-material/BarChart';
import FolderIcon from '@mui/icons-material/Folder';
import GroupIcon from '@mui/icons-material/Group';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import VpnKey from '@mui/icons-material/VpnKey';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { RolesEnum } from '../../domain/enums/roles.enum';
import { RenderIf } from '@lib/ui-components';
import {
  AllInbox,
  Assignment,
  CallReceived,
  ContactMail,
  FactCheck,
  Feed,
  Handshake,
  InfoOutlined,
  LocalShipping,
  Map,
  QuestionMark,
  SimCardDownload,
  SnippetFolder,
  Store,
} from '@mui/icons-material';
import axios from 'axios';
import { ProjectSettings } from '@lib/api-interface';

function technicalAdminMenu(t: TFunction<'common'>) {
  return (
    <div className={styles['menu']}>
      <LeftMenuLink to='organization-users' label={t('organizationUsers')} icon={<GroupIcon />} />
      <LeftMenuLink to='aggregates' label={t('settings')} icon={<SettingsIcon />} />
    </div>
  );
}

function organizationMenu(userState: UserState, t: TFunction<'common'>) {
  return (
    <div className={styles['menu']}>
      <LeftMenuLink to='projects' label={t('projects')} icon={<MenuBookIcon />} />
      <LeftMenuLink to='companies' label={t('companies')} icon={<PeopleIcon />} />
      <LeftMenuLink to={'maps'} label={t('maps')} icon={<Map />} />
      <LeftMenuLink to='collection-requests' label={t('reportsReport')} icon={<ArrowDownwardIcon />} />
      <LeftMenuLink to='communes' label={t('communes')} icon={<LocationCityIcon />} />
      <LeftMenuLink to='month-end-closing' label={t('monthEndClosing')} icon={<VpnKey />} />
      <LeftMenuLink to='bdo-errors' label={t('bdoErrors')} icon={<NotificationImportantIcon />} />
      <LeftMenuLink to='bdo-rejected-cards' label={t('bdoRejectedCards')} icon={<ThumbDownIcon />} />
      <LeftMenuLink to='reports' label={t('reports')} icon={<BarChart />} />
      <RenderIf true={userState.authorities.includes('ORGANIZATION_ADMIN')}>
        <LeftMenuLink to='global-settings' label={t('settings')} icon={<SettingsIcon />} />
      </RenderIf>
      <RenderIf true={userState.authorities.includes('ORGANIZATION_ADMIN') || userState.authorities.includes('MANAGES_USERS')}>
        <LeftMenuLink to='registers' label={t('registers')} icon={<FolderIcon />} />
      </RenderIf>
    </div>
  );
}

function subcontractorMenu(t: TFunction<'common'>) {
  return (
    <div className={styles['menu']}>
      <LeftMenuLink to='collection-requests' label={t('reportsV2')} icon={<ArrowDownwardIcon />} />
      <LeftMenuLink to='quantity-received' label={t('quantityReceived')} icon={<CallReceived />} />
      <LeftMenuLink to='archive' label={t('archive')} icon={<Feed />} />
      <LeftMenuLink to='today-pickups' label={t('todayPickups')} icon={<LocalShipping />} />
      <LeftMenuLink to='agreements' label={t('agreements')} icon={<Handshake />} />
      <LeftMenuLink to='decisions' label={t('decisions')} icon={<Assignment />} />
      <LeftMenuLink to='reports' label={t('reports')} icon={<BarChart />} />
      <LeftMenuLink to='contact-form' label={t('contactForm')} icon={<ContactMail />} />
    </div>
  );
}

function clientUserMenu(canGenerateReports: boolean, t: TFunction<'common'>) {
  return (
    <div className={styles['menu']}>
      <LeftMenuLink to='pickups' label={t('pickups')} icon={<LocalShipping />} />
      <LeftMenuLink to='infrastructure' label={t('infrastructure')} icon={<AllInbox />} />
      <LeftMenuLink to='archive' label={t('archive')} icon={<Feed />} />
      <LeftMenuLink to='commune-declarations' label={t('communeDeclarations')} icon={<SnippetFolder />} />
      <LeftMenuLink to='contact-form' label={t('contactForm')} icon={<ContactMail />} />
      <LeftMenuLink to='documents' label={t('documents')} icon={<SimCardDownload />} />
      <RenderIf true={canGenerateReports}>
        <LeftMenuLink to='reports' label={t('reports')} icon={<BarChart />} />
      </RenderIf>
      <LeftMenuLink to='instruction' label={t('clientPoiInstruction')} icon={<QuestionMark />} />
    </div>
  );
}

function clientAdminMenu(userState: UserState, seePickups: boolean, t: TFunction<'common'>) {
  return (
    <div className={styles['menu']}>
      <LeftMenuLink to='services-to-accept' label={t('servicesToAccept')} icon={<FactCheck />} />
      <LeftMenuLink to='branches' label={t('branches')} icon={<Store />} />
      <RenderIf true={seePickups}>
        <LeftMenuLink to='pickups' label={t('pickups')} icon={<LocalShipping />} />
      </RenderIf>
      <LeftMenuLink to='collection-requests' label={t('reportsV2')} icon={<ArrowDownwardIcon />} />
      <LeftMenuLink to='reports' label={t('reports')} icon={<BarChart />} />
      <LeftMenuLink to='archive' label={t('archive')} icon={<Feed />} />
      <LeftMenuLink to='client-data' label={t('Companies.CompanyView.companyClientInfo')} icon={<InfoOutlined />} />
      <LeftMenuLink to='contact-form' label={t('contactForm')} icon={<ContactMail />} />
      <RenderIf true={userState.authorities.includes('ORGANIZATION_ADMIN')}>
        <LeftMenuLink to='global-settings' label={t('settings')} icon={<SettingsIcon />} />
      </RenderIf>
      <RenderIf true={userState.authorities.includes('ORGANIZATION_ADMIN') || userState.authorities.includes('MANAGES_USERS')}>
        <LeftMenuLink to='registers' label={t('registers')} icon={<FolderIcon />} />
      </RenderIf>
      <LeftMenuLink to='instruction' label={t('clientPoiInstruction')} icon={<QuestionMark />} />
    </div>
  );
}

export const LeftMenuItemsSelector = () => {
  const userState = useUserState();
  const { t } = useTranslation();
  const [projectSettings, setProjectSettings] = React.useState<ProjectSettings | null>(null);

  React.useEffect(() => {
    if (userState.roles.includes(RolesEnum.CLIENT_USER) || userState.roles.includes(RolesEnum.CLIENT_ADMIN)) {
      const fetchSettings = async () => {
        const url = `${process.env['REACT_APP_API_URL']}/api/project-branch-view/settings`;
        try {
          const response = await axios.get(url);
          setProjectSettings(response.data);
        } catch (error) {
          console.error('Failed to fetch settings:', error);
          setProjectSettings(null); // handle error appropriately
        }
      };

      fetchSettings();
    }
  }, []);

  if (!userState.isPresent || userState.roles.length === 0) {
    return <div />;
  }

  // temporary solution for the combination of client_admin + regional_admin
  if (userState.roles.length > 1 && userState.roles.includes(RolesEnum.CLIENT_ADMIN)) {
    return clientAdminMenu(userState, projectSettings?.userAdminCanReportCollections ?? false, t);
  }

  switch (userState.roles[0]) {
    case RolesEnum.TECHNICAL_ADMIN:
      return technicalAdminMenu(t);
    case RolesEnum.ORGANIZATION:
      return organizationMenu(userState, t);
    case RolesEnum.CONTRACTOR:
      return subcontractorMenu(t);
    case RolesEnum.CLIENT_USER: {
      return clientUserMenu(projectSettings?.branchCanGenerateReports || projectSettings?.branchCanGenerateBdoReports || false, t);
    }
    case RolesEnum.CLIENT_ADMIN:
      return clientAdminMenu(userState, projectSettings?.userAdminCanReportCollections ?? false, t);
    default:
      return <div />;
  }
};
