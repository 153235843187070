import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './form-input-props';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { ErrorText } from './error-text';
import { HStack } from '../h-stack/h-stack';

export interface FormInputCheckboxProps extends FormInputProps {
  initialValue?: boolean;
}

export const FormInputCheckbox: React.FC<FormInputCheckboxProps> = ({ name, control, setValue, label, initialValue, isDeactivated }) => {
  const [selected, setSelected] = useState<boolean>(initialValue != null ? initialValue : false);

  useEffect(() => {
    setValue(name, selected);
  }, [selected, name, setValue]);

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState, formState }) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '3px',
                }}
              >
                <Checkbox
                  inputProps={{ 'data-test-id': `checkbox-${name}` } as any}
                  checked={selected}
                  onChange={(event, checked) => setSelected(checked)}
                  icon={<ToggleOffIcon fontSize='large' />}
                  checkedIcon={<ToggleOnIcon color='secondary' fontSize='large' />}
                  disabled={isDeactivated}
                />
                {fieldState.error && <ErrorText>{fieldState.error.message}</ErrorText>}
              </div>
            );
          }}
        />
      }
      label={label}
    />
  );
};
