import {
  Button,
  DataTable,
  ExportToExcelIcon,
  HStack,
  ImportFromExcelIcon,
  Panel,
  RenderIf,
  Snackbar,
  TableFilterItem,
  UsecaseButton,
  YearMonthFilterBar,
  YearMonthFilterBarValues,
} from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  MASS_PRECISION,
  PLN_PRECISION,
  QUANTITIES_PRECISION,
  ServicePriceBearer,
  ServicePriceType,
  ServiceSettings,
  ServiceType,
  SettlementType,
  SettlementViewStatus,
  SettlementViewType,
  VOLUME_PRECISION,
} from '@lib/api-interface';
import * as Yup from 'yup';
import { ExcelImportDialog } from './excel-import-dialog';
import { format, parseISO } from 'date-fns';
import { DateTimeService } from '../../../services/date-time.service';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useWebSocketState } from '../../../state/web-socket-state';
import { GreenRedDotIndicator } from '../../../libs/ui-components/components/indicator/green-red-dot-indicator';

export const ReceivedQuantity = () => {
  const { t } = useTranslation();
  const now = new Date();
  const month = now.getMonth() + 1;
  const yearMonthNow = `${now.getFullYear()}-${month > 9 ? month : '0' + month}`;
  const [tableRefreshKey, setTableRefreshKey] = React.useState(1);
  const savedFilters = sessionStorage.getItem('receivedQuantityFilters');
  const initialFilters = savedFilters ? JSON.parse(savedFilters) : { date: yearMonthNow, filter: '' };
  initialFilters.date = yearMonthNow;
  const [activeFilters, setActiveFilters] = React.useState<YearMonthFilterBarValues>(initialFilters);
  const instanceFiltersRef: React.MutableRefObject<TableFilterItem[]> = React.useRef(new Array<TableFilterItem>());
  const [importDialogOpen, setImportDialogOpen] = React.useState(false);
  const [importError, setImportError] = React.useState<Error | unknown>(null);
  const [importStarted, setImportStarted] = React.useState<boolean>(false);
  const [exportLoading, setExportLoading] = React.useState<boolean>(false);
  const webSocketState = useWebSocketState();
  const [hasPendingImportCommand, setHasPendingImportCommand] = React.useState<boolean>(false);
  const [pendingImportIndicatorRefreshKey, setPendingImportIndicatorRefreshKey] = React.useState(0);
  const [userBranchId, setUserBranchId] = React.useState<string>('');

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/contractor/assigned-branch');
        setUserBranchId(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pendingImportIndicatorRefreshKey]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTableRefreshKey((prevKey) => prevKey + 1);
      setPendingImportIndicatorRefreshKey((prevKey) => prevKey + 1);
    }, 1500);
    return () => clearTimeout(timer);
  }, [webSocketState.receivedQuantitiesImportRefreshKey]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/received-quantities/has-pending-import-command');
        setHasPendingImportCommand(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pendingImportIndicatorRefreshKey]);
  const importBlock = React.useMemo(() => {
    return (
      <div style={{ marginLeft: '1rem' }}>
        <RenderIf true={importStarted}>
          <Snackbar message={t('receivedQuantitiesImport.started')} severity='info' onClose={() => setImportStarted(false)} />
        </RenderIf>
        <RenderIf true={importError !== null}>
          <Snackbar message={t('settlementImportError')} severity='error' onClose={() => setImportError(null)} />
        </RenderIf>
        <HStack>
          <Button
            variant={'text'}
            label={t('importFromExcel')}
            startIcon={<ImportFromExcelIcon />}
            isDisabled={hasPendingImportCommand}
            onClick={() => setImportDialogOpen(true)}
          />
          <GreenRedDotIndicator showRedLight={hasPendingImportCommand} />
          <div style={{ marginLeft: '2rem' }} />
          <Button
            variant={'text'}
            label={t('exportToExcel')}
            startIcon={exportLoading ? <CircularProgress size={20} /> : <ExportToExcelIcon />}
            isDisabled={exportLoading || hasPendingImportCommand}
            onClick={() => {
              setExportLoading(true);
              axios({
                method: 'get',
                url: '/api/subcontractor/settlement/export',
                params: {
                  yearMonth: activeFilters.date,
                },
                responseType: 'blob',
              })
                .then((response) => {
                  const contentDispositionHeader = response.headers['content-disposition'];
                  let fileName = '';

                  if (contentDispositionHeader) {
                    const fileNameMatch = contentDispositionHeader.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/);
                    if (fileNameMatch && fileNameMatch.length > 1) {
                      fileName = decodeURIComponent(fileNameMatch[1]);
                    }
                  }
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', fileName);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                })
                .finally(() => setExportLoading(false));
            }}
          />
        </HStack>
        <RenderIf true={importDialogOpen}>
          <ExcelImportDialog
            open={importDialogOpen}
            onClose={(success) => {
              if (success) {
                setPendingImportIndicatorRefreshKey((prevKey) => prevKey + 1);
                setImportStarted(true);
              }
              setImportDialogOpen(false);
            }}
            onError={(error) => {
              setImportError(error);
              setImportDialogOpen(false);
            }}
          />
        </RenderIf>
      </div>
    );
  }, [importDialogOpen, importError, activeFilters.date, exportLoading, importStarted, hasPendingImportCommand]);

  const dataTable = React.useMemo(() => {
    return (
      <DataTable
        refreshKey={tableRefreshKey}
        fetchPostUrl={'api/contractor/received-quantities'}
        fetchFilters={{
          yearMonth: activeFilters.date,
        }}
        customRowStyleIfTrue={{
          condition: (row) => row.settlementStatus === SettlementViewStatus.SETTLED || row.settlementStatus === SettlementViewStatus.INVOICED,
          style: { color: '#908d95' },
        }}
        defaultTableFilters={instanceFiltersRef.current}
        onDefaultTableFiltersUpdated={(filters) => {
          instanceFiltersRef.current = filters;
        }}
        columns={[
          { key: 'companyName', label: t('companyName'), type: 'text' },
          { key: 'branchName', label: t('branchName'), type: 'text' },
          { key: 'registrationAddress.town', label: t('town'), type: 'text' },
          {
            key: 'registrationAddress.street',
            label: t('street'),
            type: 'text',
            joinedColumns: [{ key: 'registrationAddress.houseNum', label: '', type: 'numeric' }],
          },
          {
            key: 'serviceView.projectWaste.wasteCode',
            label: t('wasteCode'),
            type: 'text',
            renderer: (row) => (row.serviceType === ServiceType.ADDITIONAL ? row.serviceView.description : row.serviceView.projectWaste?.wasteCode),
          },
          { key: 'serviceView.projectWaste.wasteDescription', label: t('wasteName'), type: 'text' },
          { key: 'serviceView.projectWaste.projectWasteDescription', label: t('wasteDescription'), type: 'text' },
          {
            key: 'serviceView.currentSettings.subcontractorCollectionPrice.price',
            label: t('price'),
            type: 'numeric',
            showEmptyIfTrue: (row) => row.serviceView?.subcontractorId !== userBranchId,
            numericPrecision: PLN_PRECISION,
            joinedColumns: [
              {
                key: 'serviceView.currentSettings.subcontractorCollectionPrice.currency',
                label: '',
                type: 'text',
              },
            ],
            colorOnCondition: (row) => (row.serviceView.currentSettings.subcontractorCollectionPrice.bearer === ServicePriceBearer.ORGANIZATION_REVENUE ? '#EB5757' : '#0AAD50'),
          },
          {
            key: 'serviceView.currentSettings.subcontractorCollectionPrice.priceType',
            label: t('priceType'),
            type: 'enum',
            showEmptyIfTrue: (row) => row.serviceView?.subcontractorId !== userBranchId,
            enumValues: [
              { value: ServicePriceType.MG, label: t('priceType.MG') },
              { value: ServicePriceType.M3, label: t('priceType.M3') },
              { value: ServicePriceType.UNIT, label: t('priceType.UNIT') },
            ],
          },
          {
            key: 'serviceView.settlementType',
            label: t('settlementType'),
            type: 'enum',
            enumValues: [
              { value: SettlementType.PER_COLLECTION, label: t('settlementType.PER_COLLECTION') },
              { value: SettlementType.PER_MONTH, label: t('settlementType.PER_MONTH') },
            ],
          },
          { key: 'subcontractorReceivedValue', label: t('declared'), type: 'numeric' },
          {
            key: 'implementationDate',
            label: t('pickupDate'),
            type: 'date',
            renderer: (row) =>
              row.serviceView.settlementType === SettlementType.PER_COLLECTION && row.implementationDate ? (
                <>{format(parseISO(row.implementationDate), DateTimeService.DEFAULT_FORMAT_DATE)}</>
              ) : (
                <>-</>
              ),
          },
          {
            key: 'serviceView.currentSettings.subcontractorTransportPrice.price',
            label: t('transportPrice'),
            type: 'numeric',
            showEmptyIfTrue: (row) => row.serviceView?.transporterId !== userBranchId,
            numericPrecision: PLN_PRECISION,
            joinedColumns: [
              {
                key: 'serviceView.currentSettings.subcontractorTransportPrice.currency',
                label: '',
                type: 'text',
              },
            ],
            colorOnCondition: (row) => (row.serviceView.currentSettings.subcontractorCollectionPrice.bearer === ServicePriceBearer.ORGANIZATION_REVENUE ? '#EB5757' : '#0AAD50'),
          },
          {
            key: 'reportedTransports',
            label: t('numberOfTransports'),
            type: 'numeric',
            showEmptyIfTrue: (row) => row.serviceView?.transporterId !== userBranchId,
          },
          {
            key: 'receivedMass',
            label: t('kpoMass'),
            type: 'numeric',
            maxWidth: '10rem',
            renderer: (row) =>
              row.settlementType === SettlementViewType.SERVICE && row.serviceView.currentSettings.subcontractorCollectionPrice.priceType === ServicePriceType.MG
                ? ''
                : row.receivedMass,
          },
          { key: 'lastModifiedDate', label: t('modificationDate'), type: 'date-time' },
          { key: 'settlementId', label: '', type: 'text', excludeFromFilter: true, hidden: true },
        ]}
        rowOptions={[
          {
            isVisible: (row) => [SettlementViewStatus.UNSETTLED, SettlementViewStatus.TO_BE_ACCEPTED, SettlementViewStatus.SETTLED].includes(row.settlementStatus),
            renderer: (row) => {
              const currentSettings: ServiceSettings = row.settings;
              const priceType: ServicePriceType | undefined =
                currentSettings.subcontractorCollectionPrice?.price !== undefined ? currentSettings.subcontractorCollectionPrice.priceType : undefined;
              const hasTransportPrices = (currentSettings.subcontractorTransportPrice?.price || -1) > 0;

              return (
                <UsecaseButton
                  useCaseName='UpdateSettlementDeclarationUseCase'
                  groupName='settlement'
                  hiddenValues={{ settlementId: row.settlementId }}
                  buttonLabel={t('declare')}
                  variant={'contained'}
                  color={'secondary'}
                  isDisabled={hasPendingImportCommand}
                  fields={[
                    {
                      label: t('receivedMass'),
                      field: 'receivedMass',
                      type: 'numeric',
                      numericPrecision: MASS_PRECISION,
                      defaultValue: row.receivedMass,
                      isVisible: () => row.serviceType != ServiceType.ADDITIONAL || priceType === ServicePriceType.MG,
                    },
                    {
                      label: t('quantityReceived'),
                      field: 'receivedQuantities',
                      type: 'numeric',
                      numericPrecision: QUANTITIES_PRECISION,
                      defaultValue: row.receivedQuantities,
                      isVisible: () => priceType === ServicePriceType.UNIT,
                    },
                    {
                      label: t('volumeReceived'),
                      field: 'receivedVolume',
                      type: 'numeric',
                      numericPrecision: VOLUME_PRECISION,
                      defaultValue: row.receivedVolume,
                      isVisible: () => priceType === ServicePriceType.M3,
                    },
                    {
                      label: t('transportCompleted'),
                      field: 'reportedTransports',
                      type: 'numeric',
                      defaultValue: row.reportedTransports,
                    },
                    {
                      label: t('implementationDate'),
                      field: 'implementationDate',
                      type: 'date',
                      defaultValue: row.implementationDate,
                      isVisible: () => row.serviceView.settlementType !== SettlementType.PER_MONTH,
                    },
                  ]}
                  validationSchema={{
                    receivedMass: Yup.string().when([], {
                      is: () => row.serviceType !== ServiceType.ADDITIONAL || priceType === ServicePriceType.MG,
                      then: Yup.string().required(t('fieldRequired')),
                    }),
                    receivedQuantities: Yup.string().when([], {
                      is: () => priceType === ServicePriceType.UNIT,
                      then: Yup.string().required(t('fieldRequired')),
                    }),
                    receivedVolume: Yup.string().when([], {
                      is: () => priceType === ServicePriceType.M3,
                      then: Yup.string().required(t('fieldRequired')),
                    }),
                    reportedTransports: Yup.string().when([], {
                      is: () => hasTransportPrices || row.serviceView.onDemand,
                      then: Yup.string().required(t('fieldRequired')),
                    }),
                  }}
                  onSaved={async () => setTableRefreshKey(tableRefreshKey + 1)}
                />
              );
            },
          },
        ]}
      />
    );
  }, [tableRefreshKey, activeFilters, hasPendingImportCommand]);

  return (
    <Panel borderType='raised'>
      <HStack align={'left'}>
        <YearMonthFilterBar
          onFilterChanged={(filters) => {
            setActiveFilters(filters);
            sessionStorage.setItem('receivedQuantityFilters', JSON.stringify(filters));
            setTableRefreshKey(tableRefreshKey + 1);
          }}
          initialMonthYear={activeFilters.date}
        />
        {importBlock}
      </HStack>
      {dataTable}
    </Panel>
  );
};
