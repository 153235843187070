import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from './login-page/login-page';
import { PublicLayout } from '../../components/layouts/public-layout/public-layout';
import { ActivatePage } from './activate-page/activate-page';
import { RestorePasswordPage } from './restore-password-page/restore-password-page';

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<PublicLayout />}>
        <Route index element={<Navigate to='/login' />} />
        <Route path='login' element={<LoginPage />} />
        <Route path='activate' element={<ActivatePage />} />
        <Route path='restore-password' element={<RestorePasswordPage />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};
